import * as React from "react"
import Layout from "../components/layout"
import CarbonPassHeader from "../components/carbonpassheader2"
import GetInTouch from "../components/getintouch"
import CloudOne from "../images/plane.svg"
import bgsky from "../images/bg-cloud.svg"
import Seo from "../components/seo"

const Terms = () => (
  <Layout>
    <Seo title="Terms and condition" />
      <CarbonPassHeader/>
      <div className="relative">
      <div className="-mb-8 block pt-4 p-6 text-white bg-gradient-to-b from-carbonblue to-carbonbluelight">
          <img className='animate-[movingCloud_50s_linear_infinite] opacity-20' src={CloudOne} alt="Cloud" style={{height:"140px"}}></img>
         
          <div className="max-w-7xl mx-auto h-48">

          <h2 className="mt-10 text-5xl lg:text-7xl font-bold text-white mb-8 lg:mb-12">
          Terms and conditions
          </h2>

          </div>
          <div className='animate-[movingCloudRight_150s_linear_infinite] bg-cover block' style={{backgroundImage: `url(${bgsky})`, width:"4000px",height:"200px"}}></div>
         
      </div>
      <div className="px-6 max-w-7xl mx-auto pt-16">

      <div className="grid grid-cols-1">
        <div className="text-xl pb-14">
        <p>This page explains the terms of use for Ports of Jersey (including Jersey Airport) websites and applications. You must agree to these to use.</p>
<h3><strong>About us</strong></h3>
<p>Ports of Jersey Limited is a private limited company registered in Jersey, Channel Islands. Our registered office address is Ports of Jersey, St Peter, Jersey, JE1 1BY.</p>
<h3><strong>Using our website</strong></h3>
<p>Ports of Jersey websites and applications are maintained for your personal use and viewing.  Access and use by you of these sites and applications constitute your acceptance of these terms and conditions, taking effect from the date on which you first use the websites or applications.</p>
<p>The websites and applications provide information on our business and the services available. We have also teamed up with various external business partners to provide you with information about travel services which we think may be of interest to you.</p>
<p>We are not responsible for the content or reliability of the websites we link to and do not necessarily endorse the views expressed within them.</p>
<p>Our privacy policy does not apply to websites we link to. For information on how your data may be used by these linked websites, please refer to their individual privacy policies.</p>
<p>We aim to replace broken links to other sites but cannot guarantee that these links will always work as we have no control over the availability of other sites.</p>
<h3><strong>Your obligations when using our websites and applications </strong></h3>
<p>You may only view and use our websites and applications in accordance with these terms and, in any event, for lawful and proper purposes. These include complying with all applicable laws, regulations and codes of practice within the UK or other jurisdiction from which you are accessing this website.</p>
<p>You agree that you will not</p>
<ul>
<li>post, transmit or disseminate any information on or via our websites and applications, which is or may be harmful, obscene, defamatory or otherwise illegal</li>
<li>use our websites and applications in a manner which causes or may cause an infringement of our rights or the rights of any other</li>
<li>make any unauthorised, false or fraudulent booking</li>
<li>use any software, routine or device to interfere or attempt to interfere electronically or manually with the operation or functionality of our websites and applications, including but not limited to uploading or making available files containing corrupt data or viruses via whatever means</li>
<li>deface, alter or interfere with the front end ‘look and feel’ of our websites and applications</li>
<li>take any action that imposes an unreasonable or disproportionately large load on our websites, applications or related infrastructure</li>
<li>obtain or attempt to obtain unauthorised access, via whatever means, to any of our networks</li>
</ul>
<p>We reserve the right to suspend or terminate your access and use of our websites or applications at any time if you breach these terms.</p>
<h3><strong>Our responsibilities to you</strong></h3>
<p>Although we make every effort to ensure that the information contained on our websites and applications is relevant and useful, we cannot make any commitment that it will meet your requirements. Due to frequent changes to flight information and facilities at the airport, we cannot guarantee that the information on our websites and applications will always be completely accurate.</p>
<p>Although we make every effort to ensure that our websites and applications are always available, there will be occasions when we will need to carry out routine and emergency maintenance. We do not therefore, guarantee that it will always be available, or that they will always perform at a particular speed or with particular functionality. We reserve the right to withdraw our websites or applications if we need to.</p>
<p>To the maximum extent permitted by law, all representations, warranties, terms, conditions and commitments not expressly set out in these terms are hereby excluded.</p>
<p>Except in the case of death or personal injury caused by our negligence, or fraud, we do not accept responsibility for any loss or damage that you suffer as a result of using our websites or applications. In particular, we do not accept responsibility for any</p>
<ol>
<li>loss of profits, earnings, anticipated savings, goodwill or revenue</li>
<li>loss or corruption of data</li>
<li>indirect or consequential loss</li>
</ol>
<p>Your statutory rights are unaffected.</p>
<h3><strong>Information on flight arrivals, departures, timetables, destinations and carbon factors</strong></h3>
<p>We make every effort to ensure that the flight information contained on our websites and applications is accurate and up to date.</p>
<p>The flight arrivals and departure screens contain the scheduled times of most commercial flights. We can only update the flight arrival and departure screens once the operating airline has informed us that a particular flight is early, delayed or cancelled. Therefore, we are not able to guarantee the accuracy of that information. We always advise telephoning the airline in advance if you have concerns that your flight may have been cancelled or seriously delayed.</p>
<p>For flight destination and timetable information available on our websites and applications, we do our best to ensure that this is accurate and up to date, but we are reliant on the airlines and other third parties advising us of any changes.</p>
<h3><strong>Investor relations</strong></h3>
<p>We have included certain information on our websites and applications about our corporate affairs. Such information is provided for your convenience only. We do not give any advice, make any recommendation or endorsement as to any investment.</p>
<h3><strong>Intellectual Property Rights</strong></h3>
<p>We, or our licensors, own all trademarks, copyright, database rights and other intellectual property rights in the materials on our websites and applications (as well as the organisation and layout of our websites and applications). We also own the rights in any software, or underlying software code, that is made available for download from our websites or applications.</p>
<p>The contents of the pages contained in our websites or applications may not be distributed, displayed or copied to third parties including, but not limited to, ‘caching’ any material on our websites or applications for access by third parties and ‘mirroring’ any material on our websites or applications.</p>
<p>You may not copy, reproduce, modify, alter, publish, broadcast, distribute, display, post, sell, transfer or transmit, in any form or by any means, any material on our websites or applications or software whether in whole or in part. You may not use the material or software to create derivative works, without our prior written permission.</p>
<p>By exception, the contents of individual pages of our websites or applications may be printed or downloaded to disk for the purpose of private and personal non-commercial use. You may make an electronically stored, transient copy of the content of our websites or applications for the purpose of viewing it while connected to the Internet only.</p>
<p>You may not do any of the following without our written permission:</p>
<ul>
<li>remove the copyright or trademark notice from any copies of content under these terms; or</li>
<li>create a database in electronic or structured manual form by systematically downloading and storing any content.</li>
</ul>
<h3><strong>Feedback</strong></h3>
<p>From time to time, we may ask you to provide us with your views on our airport, harbour or marinas services, our websites, applications or any other related issue. If you are under the age of 16 years, you must have consent from a parent or guardian to contribute.</p>
<p>Any views given, together with the personal information you provide, will be governed by these terms and conditions as well as by the terms of our privacy policy.</p>
<p>By taking part, you are giving us your full consent to post your contribution on our websites or applications, should we choose to do so. You will be deemed to have given us a free licence on a perpetual basis to adapt, modify or incorporate your contribution in other works or otherwise use it as we see fit. You agree that we do not need to acknowledge you as being the author of such contribution, unless we wish to do so.</p>
<p>Any contribution you provide must not contain anything that is unlawful, offensive, abusive, threatening, defamatory, obscene or discriminatory; nor shall it infringe the rights of anyone else.</p>
<h3><strong>Changes to these terms</strong></h3>
<p>Please check these terms and conditions regularly. We can update them at any time without notice.</p>
<p>You’ll agree to any changes, if you continue to use our websites or applications after the terms and conditions have been updated.</p>
<h3><strong>General</strong></h3>
<p>These terms refer only to your viewing of our websites and applications. Separate conditions apply to prize draws, competitions and promotions that we may run from time to time, as well as to products and services sold through our websites and applications.</p>
<h3><strong>Data protection and privacy</strong></h3>
<p>Any personal information you supply to us when you use our websites or applications will be used in accordance with our privacy policy.</p>
<p><em> </em></p>
<p><em>Last updated: March 2022</em></p>
</div></div>
</div></div>
        <GetInTouch/>
  </Layout>
)

export default Terms
